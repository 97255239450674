[data-theme="light"] {
  --main_background:#f4f2ee;
  --header_background:#fff;
  --search_input_background:#edf3f8;
  --basic_text_color:#000;
  --profile_image_border:#5a5a5a;
  --nav_link_color_hover:#111;
  --block_shadow:3px 6px 16px -2px #a0a0a0;
  --profile_drop_content:#3c3c3c;
  --popup_background:rgba(0,0,0,0.5);
  --popup_header_background:#f2f2f2;
  --search_button_hover:#d9e6f0;
  --good_search_hover:#f2f2f2;
  --chat_body_background:#f2f2f2;
  --chat_title_color:#5e5e5e;
  --message_input_background:#fff;
  --message_input_border:#dcdcdc;
  --main_section_border:#dcdcdc;
  --main_company:#636363;
  --more_hover_backgrond:#e8e8e8;
  --info_color:#404040;
  --header_shadow:rgb(177, 177, 177);
  --projects_border:#d0d0d0;
  --projects_more_hover:#e8e8e8;
  --back_background:#e1e1e1;
  --back_background_hover:#c5c5c5;
  --tab_button_hover:#ebebeb;
  --tab_button_selected:green;
  --disable_not_button:#c2c2c2;
  --signup_input_border:#adadad;
}

[data-theme="dark"] {
  --main_background:#000000;
  --header_background:#1b1f23;
  --search_input_background:#38434f;
  --basic_text_color:#f2f2f2;
  --profile_image_border:#cfcfcf;
  --nav_link_color_hover:#c2c2c2;
  --block_shadow:  0px 0px 8px -3px rgba(176,176,176,0.9);
  --profile_drop_content:#b0b0b0;
  --popup_background:rgba(0,0,0,0.5);
  --popup_header_background:#293138;
  --search_button_hover:#2c3640;
  --good_search_hover:#2d3033;
  --chat_body_background:#303030;
  --chat_title_color:#c0c0c0;
  --message_input_background:#4e4e4e;
  --message_input_border:#222222;
  --main_section_border:#2c2c2c;
  --main_company:#b9b9b9;
  --more_hover_backgrond:#293037;
  --info_color:#c2c2c2;
  --header_shadow:rgb(79, 79, 79);
  --projects_border:#303030;
  --projects_more_hover:#2d3033;
  --back_background:#a3a3a3;
  --back_background_hover:#909090;
  --tab_button_hover:#3a3a3a;
  --tab_button_selected:rgb(131, 216, 131);
  --disable_not_button:#515151;
  --signup_input_border:#565656;
}
*{
  margin:0;
  padding:0;
  border:0;
  outline:none;
}
body, html{
  background-color: var(--main_background);
  font-family: Arial, Helvetica, sans-serif;
  transition: 0.3s ease;
  scroll-behavior: smooth;
}
.section_header{
  width: 100%;
  background-color: var(--header_background);
  position: fixed;
  top:0px;
  height:60px;
  display: flex;
  align-items: center;
  z-index: +999;
  box-shadow: 0px 0px 5px var(--header_shadow);
}
.header_wrapper{
  margin:auto;
  width:100%;
  max-width: 1020px;
  padding:0px 40px;
  display: flex;
  justify-content: space-between;
}
.logo_search_wrapper{
  display:flex;
  position:relative;
}
.logo{
  height:35px;
  width:35px;
  cursor: pointer;
}
.logo_img{
  height:35px;
  width:35px;
}
.search{
  background-color: var(--search_input_background);
  border-radius: 3px;
  width:200px;
  height:35px;
  margin-left:10px;
  display: flex;
  color:var(--basic_text_color);
}
#search_button{
  display: flex;
  align-items: center;
  justify-content: center;
  width:40px;
  font-size:14px;
  cursor: pointer;
  background-color: transparent;
  border-radius: 3px 0px 3px 0px;
}
#search_button:hover{
  transition:0.3s ease;
  background-color: var(--search_button_hover);
}
.profile_arrow{
  color:var(--basic_text_color);
}
.search_input{
  background-color: transparent;
  padding:0px 10px 0px 5px;
  width:100%;
  color:var(--basic_text_color);
}
.searchResults{
  position:absolute;
  top:40px;
  width:250px;
  background-color: var(--header_background);
  border-radius: 3px;
  display:none;
  -webkit-box-shadow: var(--block_shadow);
  -moz-box-shadow: var(--block_shadow);
  box-shadow: var(--block_shadow);

}
.search_profile_wrapper{
  display: flex;
  justify-content: space-between;
  padding:10px 10px;
  border-bottom: 1px solid #c2c2c2;
  cursor: pointer;
}
.search_profile_wrapper:hover{
  transition: 0.2s ease;
  background-color: var(--good_search_hover);
}
.search_profile_img{
  height:40px;
  width:40px;
}
.search_profile_img img{
  height:40px;
  width:40px;
  border-radius: 100px;
}
.serach_profile_info{
  text-align: left;
  width:100%;
  margin-left:10px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
}
.search_info_work{
  font-size:12px;
  color:#a2a2a2;
}
.good_no_results{
  color:#0274b3;
  font-weight:bold;
  font-size:13px;
  margin:10px 0px;
}
.no_search_results{
  color:#0274b3;
  font-weight:bold;
  font-size:13px;
  margin:20px 0px;
}
nav{
  display: flex;
}
.nav_element{
  display: flex;
  align-items: center;
  flex-direction: column;
}
.nav_link{
  color:#888888;
  text-decoration: none;
  margin-right:40px;
  cursor: pointer;
}
.nav_link:hover{
  transition: 0.3s ease;
  color:var(--nav_link_color_hover);
}
.nav_lint_not:hover{
  color:#888888;
}
.nav_icon{
  font-size: 22px;
}
.nav_text{
  font-size: 13px;
}
.profile_wrapper{
  display: flex;
  align-items: center;
  position: relative;
  cursor: pointer;
}
.nav_profile_wrapper{
  display: flex;
}
.profile_image{
  width:35px;
  height: 35px;
  margin-right:5px;
  border:1px solid var(--profile_image_border);
  border-radius: 60px;
  overflow: hidden;
}
.profile_image img{
  width:35px;
  height: 35px;
}
.profile_dropdown{
  position:absolute;
  top:55px;
  right:0px;
  width:230px;
  transition: 0.3s ease;
  display: none;
  opacity: 0;
  background-color: var(--header_background);
  border-radius: 3px;
  -webkit-box-shadow: var(--block_shadow);
  -moz-box-shadow: var(--block_shadow);
  box-shadow: var(--block_shadow);
  padding:10px;
  cursor: default;
  z-index: +999;
}
.profile_drop_me{
  display: flex;
  align-items: center;
}
.profile_drop_image{
  width:55px;
  height: 55px;
  overflow: hidden;
  border-radius: 100px;
}
.profile_drop_image img{
  width:100%
}
.profile_drop_info{
  text-align: left;
  margin-left:5px;
}
.profile_drop_name{
  font-weight: bold;
  color:var(--basic_text_color);
}
.profile_drop_work{
  font-size:12px;
  color:#909090;
}
.view_profile_button{
  color:#0274b3;
  background-color: transparent;
  border: 2px solid #0274b3;
  font-weight: bold;
  width:100%;
  padding:3px 0px;
  border-radius: 60px;
  margin:10px 0px 15px;
  cursor: pointer;
}
.view_profile_button:hover{
  transition: 0.3s ease;
  background-color: #c9dde8;
}
.horizontal_profile{
  width:100%;
  border: 0;
  height:1px;
  background-color: #c2c2c2;
  margin-bottom:15px;
}
.pd_title{
  color: var(--profile_drop_content);
  text-align: left;
  font-weight: bold;
  font-size: 15px;
  margin-bottom:10px;
}
.pd_links{
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  text-align: left;
}
.pd_link{
  margin-bottom:10px;
  font-size: 13px;
  background-color: transparent;
  color:var(--profile_drop_content);
  cursor:pointer;
}
.pd_link:hover{
  text-decoration: underline;
}
.signuout_wrapper{
  width:100%;
  text-align: left;
  margin-bottom:10px;
}
.signout{
  text-decoration: none;
}
.mobile_nav{
  display:none;
}
/* Switch CSS start */
.switch {
  position: relative;
  display: inline-block;
  width: 30px;
  height: 17px;
}

.switch input { 
  opacity: 0;
  width: 0;
  height: 0;
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  -webkit-transition: .4s;
  transition: .4s;
}

.slider:before {
  position: absolute;
  content: "";
  height: 13px;
  width: 13px;
  left: 2px;
  bottom: 2px;
  background-color: white;
  -webkit-transition: .4s;
  transition: .4s;
}

input:checked + .slider {
  background-color: #0274b3;;
}

input:focus + .slider {
  box-shadow: 0 0 1px #0274b3;
}

input:checked + .slider:before {
  -webkit-transform: translateX(13px);
  -ms-transform: translateX(13px);
  transform: translateX(13px);
}

/* Rounded sliders */
.slider.round {
  border-radius: 17px;
}

.slider.round:before {
  border-radius: 50%;
}
/*Switch CSS End*/
.settings_wrapper{
  background-color: var(--popup_background);
  height:100vh;
  width:100%;
  position: fixed;
  top:0px;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: +9999999;
}
.settings_block{
  background-color: var(--header_background);
  width:90%;
  max-width: 450px;
  border-radius: 5px;
}
.settings_header{
  display:flex;
  justify-content: space-between;
  align-items: center;
  border-radius: 5px;
  background-color: var(--popup_header_background);
  padding:15px;
}
.close_popup{
  background-color: transparent;
  color:var(--basic_text_color);
  font-size:20px;
  cursor: pointer;
  border-radius: 3px;
}



.popup_title{
  color:var(--basic_text_color);
  font-size:20px;
  font-weight: bold;
}
.settings_content{
  background-color: var(--header_background);
  padding:15px;
  border-radius:0px 0px 5px 5px;
}
.settings_content_element{
  text-align: left;
}
.setting_content_title{
  color:var(--basic_text_color);
  font-weight: bold;
  font-size: 16px;
  margin-bottom:15px;
}
.setting_content_row{
  display:flex;
  justify-content: space-between;
  margin-bottom:10px;
  align-items: center;
}

.faq_row{
  cursor: pointer;
  flex-direction: column;
}

.faq_flex{
  display:flex;
  justify-content: space-between;
  align-items: center;
  width:100%;
}

.setting_content_hr{
  width:100%;
  border: 0;
  height:1px;
  background-color: #c2c2c2;
  margin-bottom:15px;
}
.settings_row_title{
  font-size:15px;
  font-weight: bold;
  color:var(--basic_text_color);
}
.settings_row_text{
  color:#888888;
  font-size:13px;
}
.faq_title{
  margin-right:10px;
}

.faq_title:hover{
  color:#0274b3;
  transition: 0.3s ease;
}
.answer_body{
  margin-top:10px;
}
.answer_text{
  font-size:14px;
  margin-right:20px;
  color:var(--chat_title_color);
}
.chat_wrapper{
  height:40px;
  width:250px;
  background-color: var(--chat_body_background);
  position:fixed;
  bottom:0px;
  right:10px;
  transition: 0.3s ease;
  z-index: 0;
  -webkit-box-shadow: var(--block_shadow);
  -moz-box-shadow: var(--block_shadow);
  box-shadow: var(--block_shadow);
}
.chat_header{
  width:230px;
  background-color: var(--header_background);
  height:40px;
  padding:0px 10px;
  margin:auto;
  border-radius: 3px 3px 0px 0px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
}
.chat_profile{
  position: relative;
}
.cp_image_wrapper{
  height:30px;
  width:30px;
  border-radius: 50px;
  overflow: hidden;
}
.cp_image{
  width:100%;
}
.online_circle{
  background-color: green;
  height:8px;
  width:8px;
  border:2px solid var(--header_background);
  border-radius: 30px;
  position:absolute;
  bottom:-2px;
  right:0px;
}
.chat_messaging{
  color:var(--chat_title_color);
  text-align: left;
  width:100%;
  margin-left:5px;
  font-size:13px;
  font-weight: bold;
}
.chat_opn_close{
  color:var(--chat_title_color);
}
.chat_input_wrapper{
  width:100%;
  padding:15px 10px 0px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}
.message_title{
  text-align: left;
  font-weight:bold;
  color:var(--chat_title_color);
  font-size:13px;
  margin-bottom:5px;
}
.message_input{
  width:210px;
  padding:7px 10px;
  margin-bottom:10px;
  border-radius: 3px;
  background-color: var(--message_input_background);
  border: 1px solid var(--message_input_border);
  color:var(--basic_text_color);
}
#message_text{
  width:210px;
  padding:7px 10px;
  margin-bottom:10px;
  height:120px;
  max-width: 210px;
  max-height:120px;
  min-width: 210px;
  min-height:120px;
  border-radius: 3px;
  background-color: var(--message_input_background);
  border: 1px solid var(--message_input_border);
  color:var(--basic_text_color);
  font-family: Arial, Helvetica, sans-serif;
}
.message_send{
  background-color:#0274b3;
  padding:8px 20px;
  border-radius: 60px;
  font-weight: bold;
  color:#f2f2f2;
  cursor: pointer;
}
.message_send:hover{
  background-color:#0087cf;
  transition: 0.3s ease;
}
.main_section{
  margin:0px auto 20px;
  width:100%;
  max-width: 1020px;
  border-radius: 5px;
  border:1px solid var(--main_section_border);
  background-color: var( --header_background);
}
.main_profile_section{
  margin-top:80px;
}
.main_cover_wrapper{
  width:100%;
  height: 250px;
  overflow: hidden;
  display:flex;
  align-items: center;
  position: relative;
  border-radius: 5px 5px 0px 0px;
}
.cover_image{
  width: 100%;
  height:255px;
  object-fit: cover;
  object-position: right;
}
.main_body_wrapper{
  padding:0px 40px;
  position: relative;
}
.main_profile_wrapper{
  position: relative;
  padding:70px 0px 20px;
}
.mp_image_wrapper{
  height:170px;
  width:170px;
  border-radius: 200px;
  position:absolute;
  top:-120px;
  left:30px;
  background-color: var( --header_background);
  border:4px solid var( --header_background);
  overflow: hidden;
}
.mp_image_wrapper img{
  width:100%;
}
.mp_info_wrapper{
  text-align: left;
  padding:0px 20px;
  display: flex;
  justify-content: space-between;
}
.mp_name{
  font-size: 25px;
  color:var(--basic_text_color);
}
.mp_company{
  margin-top:5px;
  font-size:17px;
  color:var(--main_company);
}
.comanies_wrapper{
  width:fit-content;
}
.company_block{
  display: flex;
  align-items: center;
  margin-bottom:10px;
}
.company_image{
  width:35px;
  height:35px;
  overflow: hidden;
  border-radius: 60px;
}
.company_image img{
  width:100%;
}
.company_name{
  font-size:13px;
  font-weight: bold;
  color:var(--main_company);
  margin-left:5px;
}
.location_contact_wrapper{
  display: flex;
  justify-content: flex-start;
  margin-top:20px;
  color:var(--main_company);
  font-size:13px;
}
.dot{
  position: relative;
  margin:0px 3px;
  bottom:3px;
  font-weight: bold;
}
.dot1{
  position: relative;
  margin:0px 2px;
  bottom:4px;
}

.mp_contact_info{
  background-color: transparent;
  font-weight: bold;
  color:#0274b3;
  cursor: pointer;
}
.mp_contact_info:hover{
  text-decoration: underline;
}
.connections{
  font-weight: bold;
  color:#0274b3;
  font-size:13px;
  margin:10px 0px;
}
.mp_message_button{
  background-color: #0274b3;
  border-radius: 60px;
  color:#fff;
  padding:6px 15px;
  font-weight: bold;
  cursor: pointer;
  margin-right:10px;
}
.mp_message_button:hover{
  background-color: #015d8f;
  transition: 0.3s ease;
}
.mp_more_button{
  background-color: var(--header_background);
  border-radius: 60px;
  color:var(--chat_title_color);
  padding:6px 15px;
  font-weight: bold;
  border: 1px solid var(--chat_title_color);
  cursor: pointer;
}
.mp_more_button:hover{
  background-color: var(--more_hover_backgrond);
  transition: 0.3s ease;
}
.info_wrapper{
  top:0px;
  left:0px;
}
.info_content_element{
  text-align: left;
  margin-bottom:20px;
}
.inf_content_wrapper{
  display: flex;
  align-items: center;
  color: var(--info_color);
}
.inf_icon{
  width: 30px;
  font-size: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.inf_title{
  margin-left:5px;
  font-weight: bold;
  font-size:15px;
}
.info_value{
  margin:5px 0px 0px 35px;
  font-size:13px;
  color:var(--info_color);
}
.icon_link{
  color:#0274b3;
  font-weight: bold;
  text-decoration: none;
}
.icon_link:hover{
  text-decoration: underline;
}
.edit_cover_button{
  position: absolute;
  top:15px;
  right:15px;
  border-radius: 60px;
  background-color: #f2f2f2;
  color:#2c2c2c;
  padding:7px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}
.edit_cover_button:hover{
  background-color: #c1c1c1;
  transition: 0.3s ease;
}
.cover_wrapper{
  top:0px;
  left:0px;
}
.cover_block{
  max-width: 800px;
}
.cover_body{
  padding:20px;
}
.current_cover{
  border-radius: 5px;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 200px;
  margin-bottom:15px;
}
#current_image{
  height: 205px;
}
.cover_title{
  margin-bottom:10px;
  text-align: left;
  font-weight: bold;
  font-size:15px;
  color: var(--info_color);
}
.cover_library{
  display: flex;
  justify-content: space-between;
}
.library_element{
  width:30%;
  overflow: hidden;
  border-radius: 5px;
  height: 70px;
  cursor: pointer;
}
.library_element img{
  width:100%;
  height: 70px;
  object-fit: cover;
  object-position:right;
}

.cover_hr{
  width:100%;
  border: 0;
  height:1px;
  background-color: #c2c2c2;
  margin:15px 0px;
}
.cover_buttons{
  display: flex;
}
.cover_second_button{
  margin-left: 15px;
}
.c_button{
  background-color: transparent;
  border-radius: 60px;
  padding:6px 15px;
  font-weight: bold;
  cursor: pointer;
}
.cover_apply{
  background-color: #0274b3;
  color:#fff;
}
.cover_apply:hover{
  background-color: #015d8f;
  transition: 0.3s ease;
}
.cover_reset{
  color: var(--main_company);
  border:1px solid var(--main_company);
}
.cover_reset:hover{
  background-color: var(--more_hover_backgrond);
  transition: 0.3s ease;
}
.about_wrapper{
  padding:30px 40px 30px 20px;
  text-align: left;
}
.section_title{
  font-size: 22px;
  font-weight: bold;
  color:var(--basic_text_color);
}
.about_content{
  color:var(--basic_text_color);
}
.about_button{
  background-color: transparent;
  color: var(--profile_image_border);
  cursor: pointer;
  font-size:15px;
  text-decoration: underline;
}

.about_button:hover{
  transition: 0.3s ease;
  color: #0087cf;
}
.mp_profile_buttons{
  position: relative;
  display: flex;
  width: fit-content;
}
.mp_more_wrapper{
  position: relative;
}
.more_wrapper{
  position: absolute;
  top:35px;
  left:0px;
  border-radius: 3px;
  width: 180px;
  background-color: var(--header_background);
  -webkit-box-shadow: var(--block_shadow);
  -moz-box-shadow: var(--block_shadow);
  box-shadow: var(--block_shadow);
}
.more_btn{
  width: 100%;
  padding: 14px 0px 14px 8px;
  text-align: left;
  background-color: transparent;
  cursor: pointer;
  text-decoration: none;
  color:var(--basic_text_color);
  font-size:14px;
}
.more_btn:first-child{
  border-radius:3px 3px 0px 0px;
}
.more_btn:last-child {
  border-radius:0px 0px 3px 3px;
}
.more_btn:hover{
  transition: 0.3s ease;
  background-color: var(--good_search_hover);
}
.toast_wrapper{
  width:100%;
  height:50px;
  position: absolute;
  top:-100px;
  z-index: +999999;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
}
.toast_button{
  background-color: white;
  width:fit-content;
  padding:10px 10px;
  position: relative;
  top:0px;
  border-radius: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: 1s;
  opacity: 0;
  margin-bottom:10px;
  border:1px solid #c2c2c2;
}
.green_check{
  color:green;
  margin-right:8px;
}
.red_check{
  color:red;
  margin-right:8px;
}
.toast_message{
  padding-right:15px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.toast_close{
  cursor: pointer;
  color:gray;
  font-size:20px;
}
.ex_main{
  display: flex;
  margin-top:30px;
}
.ex_logo_wrapper, .ex_logo_wrapper img{
  width:50px;
}
.ex_main_info{
  margin-left:10px;
}
.ex_title{
  color:var(--basic_text_color);
  text-decoration: none;
  font-size:17px;
  font-weight: bold;
  background-color: transparent;
  cursor: pointer;
}
.ex_title:hover{
  color:#015d8f;
  text-decoration: underline;
  transition: 0.3s ease;
}
.ex_title_info{
  font-size: 13px;
  color: var(--chat_title_color);
  margin-top:8px;
}
.ex_body{
  margin:20px 0px 0px 70px;
  font-size:15px;
  color:var(--basic_text_color);
}
.pro_body{
  margin:20px 0px 0px 70px;
}
.ex_pos{
  margin-top:8px;
  color:var(--basic_text_color);
}

.ex_body_element:first-child{
  margin-bottom:10px;
}
.relative_section{
  position:relative;
}
#experience, #projects, #skills{
  position: absolute;
  top:-80px;
  height:0px;
  width: 0px;
}
.projects_hr{
  width:100%;
  height: 1px;
  background-color: var(--projects_border);
  margin-top:30px;
}
.all_projects{
  border-top: 1px solid var(--projects_border);
  width: 100%;
  padding:12px 0px;
  cursor: pointer;
  color: var(--chat_title_color);
}
.all_projects:hover{
  transition: 0.3s ease;
  background-color: var(--projects_more_hover);
}
.project_img_wrap{
  background-color: #fff;
  height:fit-content;
  padding:5px;
  border-radius: 3px;
  border:1px solid #f2f2f2
}
.skill_title{
  font-weight: bold;
  color:var(--basic_text_color);
  font-size: 18px;
  margin-bottom:10px;
}
.skill_ver{
  display: flex;
  align-items: center;
}
.skill_pass_text{
  margin-left:10px;
  color:var(--basic_text_color);
}
.skill_check_img, .skill_check_img img{
  width:23px;
}
.skill_endorsements{
  margin:10px 0px 0px 2px;
  color:var(--profile_image_border);
}
.skill_wrapper:first-child{
  margin-top:0px;
}
.skill_wrapper{
  margin-top:30px;
}
.skill_title_wrapper{
  display: flex;
  align-items: center;
  margin-bottom:20px;
}
.back_skills{
  background-color: var(--back_background);
  width:45px;
  height:45px;
  border-radius: 150px;
  font-size:20px;
  margin-right:10px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}
.back_skills:hover{
  transition: 0.3s ease;
  background-color: var(--back_background_hover);
}
.language_title{
  color:var(--basic_text_color);
  font-size:18px;
  font-weight: bold;
}
.language_proficiency{
  margin-top:10px;
  color: var(--profile_image_border);
}
.languages_wrapper{
  margin-top:20px;
}
.languages_hr{
  margin:20px 0px;
}
.interests_tabs{
  display: flex;
  padding:0px 15px;
  border-bottom: 1px solid var(--projects_border);;
}

.interests_wrapper{
  padding:30px 20px 5px 20px;
  text-align: left;
}
.interests_wrapper_second{
  padding:20px 20px 30px 20px;
  text-align: left;
}
.tab_button{
  padding:15px 8px;
  font-size:16px;
  margin-right:15px;
  cursor: pointer;
  background-color: transparent;
  font-weight: bold;
  color: var(--profile_image_border);
  border:2px solid var(--header_background);
}


.tab_button:hover{
  transition: 0.3s ease;
  background-color: var(--tab_button_hover);
}
.footer{
  margin:60px auto 60px;
  width:100%;
  max-width: 1020px;
  text-align: center;
  color:var(--profile_image_border);
  font-size:14px;
}
.interest_main{
  display: flex;
  align-items: center;
}
.interest_image_wrapper, .interest_image_wrapper img{
  width: 55px;
  border-radius: 3px;
}
.interest_info{
  margin-left:10px;
}
.interest_title{
  font-weight: bold;
  font-size:17px;
  color:var(--basic_text_color);
  text-decoration: none;
  cursor: pointer;
}

.interest_title:hover{
  color:#015d8f;
  text-decoration: underline;
  transition: 0.3s ease;
}

.interest_follow{
  margin-top:5px;
  color: var(--profile_image_border);
  font-size: 15px;
}
.interest_follow_button{
  margin-left:65px;
  border: 2px solid var(--profile_image_border);
  color:var(--profile_image_border);
  width:120px;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 30px;
  border-radius: 60px;
}
.interests_row{
  display:flex;
  justify-content: space-between;
  max-width:600px;
}
.other_interests_row{
  margin-top:30px;
}
.ab_title{
  margin-bottom:20px;
}
#honeypot{
  display:none;
}
.sing_project_section{
  margin-top:100px;
}
.sproject_wrapper{
  display:flex;
  margin: 20px;
}
.main_preview{
  width:600px;
}
.p_image_wrapper{
  width:600px;
  height:300px;
  max-height:300px;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  border:1px solid #f2f2f2;
  border-radius: 5px;
}
.p_image_wrapper img{
  width:100%;
  border-radius: 5px;
  object-fit: cover;
}
#main_preview{
  max-height:300px;
}
.preview_button_wrapper{
  position: absolute;
  width:100%;
  height:300px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.preview_block{
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  overflow: hidden;
}
.arrow_preview{
  background-color: #c2c2c2;
  border-radius: 50px;
  height:25px;
  width: 25px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}
.arrow_preview:hover{
  background-color: #959595;
  transition: 0.3s ease;
}
.p_left_arrow{
  margin-left:15px;
}
.p_right_arrow{
  margin-right:15px;
}
.simage_previews{
  width: 100%;
  display: flex;
  justify-content: space-between;
  margin-top:30px;
}
.simage_preview{
  width:80px;
  height:80px;
  border-radius: 5px;
  border:1px solid #f2f2f2;
  cursor: pointer;
  border:2px solid #c2c2c2;
}
.simage_preview:first-child{
  border:2px solid #0070ad;
}
.simage_preview img{
  width:80px;
  height:80px;
  object-fit: cover;
  border-radius: 5px;
}
.project_right{
  margin-left:40px;
}
.p_project_title{
  color:var(--basic_text_color);
  font-size: 25px;
  text-align: left;
}
.p_about_title{
  color:var(--basic_text_color);
  font-size: 20px;
  text-align: left;
  margin-bottom:20px;
}
.p_hr{
  width:70px;
  background-color: var(--info_color);
  height:3px;
  border: none;
  margin:10px 0px 20px;
}
.p_detail_element{
  text-align: left;
  margin-bottom:15px;
}
.p_detail_title{
  color:var(--basic_text_color);
  font-weight: bold;
}
.p_basic, .p_about_content{
  line-height: 1.3;
  color:var(--info_color);
}
.p_status{
  font-weight: bold;
}
.p_link{
  color:#0070ad;
  font-weight: bold;
  text-decoration: none;
}
.p_link:hover{
  text-decoration: underline;
}
.margin_around{
  margin:0px 10px;
}
.back_single_project{
  margin:20px 0px 0px 20px;
}
.back_singl{
  display: flex;
}
.not_element{
  position: relative;
}
#notification_number{
  width:15px;
  height:15px;
  border-radius: 60px;
  background-color: rgb(206, 0, 0);
  font-weight: bold;
  color:#fff;
  display: none;
  justify-content: center;
  align-items: center;
  font-size:9px;
  position:absolute;
  right:18px;
  top:-2px;
}
.notification_block{
  position: absolute;
  display:block;
  left:0px;
  top: 55px;
  background-color: var(--header_background);
  border-radius: 3px;
  -webkit-box-shadow: var(--block_shadow);
  -moz-box-shadow: var(--block_shadow);
  box-shadow: var(--block_shadow);

}
.notification_content{
  width:fit-content;
  padding:15px 5px 5px;
  position:relative;
}
.not_wrapper{
  width:100%;
  display: flex;
  align-items: center;
}
.not_profile{
  height:40px;
  width:40px;
}
.not_profile img{
  height:40px;
  width:40px;
  border-radius: 100px;
}
.not_content_text{
  text-align: left;
  font-size:12px;
  margin-bottom:5px;
  color:var(--info_color)
}
.not_cont_wrapper{
  margin:0px 5px;
  width:180px;
}
.not_hr{
  width:100%;
  height: 1px;
  background-color: var(--projects_border);
  margin:5px 0px;
}
.clear_not_button{
  background-color: transparent;
  color: #0070ad;
  font-weight: bold;
  font-size:12px;
  cursor: pointer;
}
.clear_not_button:hover{
  text-decoration: underline;
}
.no_not_content{
  font-size:12px;
  font-weight: bold;
  color: #0070ad;
  width:170px;
  padding:10px 0px 15px;
}
.close_not{
  position: absolute;
  right:5px;
  top:5px;
  font-size:14px;
  color:var(--basic_text_color);
  width:fit-content;
  z-index: +999;
}
.no_new_wrapper{
  padding-top:5px;
  position: relative;
}
.not_hover:hover{
  transition: 0.3s ease;
  color:var(--nav_link_color_hover);
}
#profile_not{
  width:15px;
  height:15px;
  border-radius: 60px;
  background-color: rgb(206, 0, 0);
  font-weight: bold;
  color:#fff;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size:9px;
  margin-left:5px;
}
.pf_not_link{
  display: flex;
}
.move_bel{
  margin-right:5px;
}
.mobile_notifications{
  display:none;
}
.mobile_not_button{
  display: flex;
  align-items: center;
  justify-content: center;
}
.not_wrapper_mob{
  margin:10px 0px 20px;
}
.not_profile{
  height:50px;
  width:50px;
}
.not_profile img{
  height:50px;
  width:50px;
}
.not_cont_wrapper_mob{
  margin:0px 10px 0px 15px;
  width:100%;
  display: flex;
  justify-content: center;
  flex-direction: column;
}
.not_content_text_mob{
  font-size:14px;
}
.mobile_not_block{
  width:300px;
}
.no_not_content_mob{
  font-size:14px;
  text-align: center;
  width:100%;
  padding:10px 0px 10px;
}
.login_wrapper{
  width: 100%;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
}
.sign_block{
  width:90%;
  max-width: 280px;
}
.sign_header{
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom:20px;

}
.sign_image{
  width:30px;
  height:30px;
}
.sign_title{
  color: var(--basic_text_color);
  font-size: 25px;
  margin-left:10px;
}
.sign_body{
  padding:20px 15px;
  background-color: var(--header_background);
  border-radius: 5px;
}
.signup_element{
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.signup_input{
  border:1px solid var(--signup_input_border);
  background-color: transparent;
  width: 100%;
  max-width: 240px;
  padding:8px 5px;
  border-radius: 3px;
  color:var(--basic_text_color)
}
.sign_label{
  color: var(--basic_text_color);
  margin-bottom:5px;
  font-weight: bold;
  font-size: 13px;
}
.second_sign_element{
  margin-top:20px;
}
.sign_button_wrapper{
  margin-top:20px;
}
.sign_button{
  background-color: #0070ad;
  color: #fff;
  padding:10px 0px;
  border-radius: 3px;
  width: 100%;
  font-weight: bold;
  cursor: pointer;
}

.sign_button:hover{
  background-color: #0091df;
  transition: 0.3s ease;
}
.sign_error{
  color:red;
  font-weight: bold;
  font-size:12px;
  margin-top:20px;
}

.second_error{
  margin-top:5px;
}
.admin_wrapper{
  width:90%;
  max-width: 1000px;
  margin:30px auto;
}
.admin_header{
  width: 100%;
  margin:auto;
  display: flex;
  justify-content: space-between;
  color: var(--basic_text_color);
}
.admin_header_left{
  width: fit-content;
  display: flex;
  align-items: center;
}
.admin_img{
  height: 35px;
  width: 35px;
  cursor: pointer;
}
.admin_header p{
  font-size: 25px;
  margin-left:10px;
}
.admin_logout{
  color:rgb(214, 0, 0);
  background-color: transparent;
  font-weight: bold;
  font-size: 17px;
  cursor: pointer;
}
.logout_icon{
  position: relative;
  top:1px;
}
.admin_stats{
  margin-top:50px;
}
.admin_title{
  text-align: left;
  font-weight: normal;
  font-size:30px;
  color:var(--basic_text_color);
}
.stats_element{
  text-align: left;
  margin-top:15px;
  display: flex;
  color:var(--basic_text_color)
}
.views_value{
  margin-left:5px;
}
.admin_messages{
  margin-top:50px;
}
.message_header{
  width: 100%;
  height:40px;
  display: flex;
}
.message_element{
  width:20%;
  border:2px solid var(--main_background);
  padding:10px 0px;
  background-color:#fff;
  text-align: left;
}
.message_element_header{
  background-color:var(--back_background_hover);
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: bold;
  border:2px solid var(--main_background);
}
.message_element:nth-child(1) {
  width:5%;
  text-align: center;
}
.message_element:nth-child(2) {
  width:20%;
}
.message_element:nth-child(3) {
  width:20%;
}
.message_element:nth-child(4) {
  width:50%;
}
.message_element:nth-child(5) {
  width:5%;
  text-align: center;
}
.messages{
  margin-top:15px;
}
.message_row{
  width: 100%;
  display: flex;
}
.padding_mess{
  margin:0px 5px;
}
.trash_mess{
  color:rgb(214, 0, 0);
  cursor: pointer;
}
.experience_content{
  position: relative;
}
.scroll_project{
  position: absolute;
  top:-90px;
}