

@media only screen and (max-width: 1120px) {

  .margin_around{
    margin:0px;
  }
}

@media only screen and (max-width: 1050px) {
  .sproject_wrapper{
    flex-direction: column-reverse;
  }
  .sing_project_section{
    max-width: 640px;;
  }
  .project_right{
    margin:0px 0px 20px;
  }
  .project_left, #main_preview,.simage_previews, .p_image_wrapper, .preview_button_wrapper{
    width:100%;
    height:auto;
    max-width: 600px;
}
.p_image_wrapper,#main_preview{
    max-height:300px;
}
.sp_sec_wrapper{
  margin:0px 10px;
}
}


@media only screen and (max-width: 850px) {
    .header_wrapper, .main_body_wrapper{
        padding:0px 20px;
    }
}
@media only screen and (max-width: 800px) {
  .navbar_wrapper{
     display:none;
  }
  .mobile_nav{
    display: block;
  }
  .main_cover_wrapper{
    height: 150px;
  }
  .cover_image{
    height:155px;
  }
  .mobile_notifications{
    display:flex;
  }
}

@media only screen and (max-width: 600px) {
  
 .current_cover{
    height:120px;
  }
  #current_image{
    height: 125px;
  }
  .library_element{
    height: 50px;
  }
  .library_element img{
    height: 50px;
  }
  .interests_row{
    flex-direction: column;
  }
  .other_interests_row{
    margin-top:0px;
  }
  .interest_wrapper{
    margin-top:30px;
  }
  .interests_wrapper_second{
    margin-top:-30px;
  }
  .simage_preview{
    width:60px;
    height:60px;
  }
  .simage_preview img{
    width:60px;
    height:60px;
  }
  .preview_button_wrapper{
    top:45%;
  }
  .arrow_preview{
    height:35px;
    width: 35px;
  }
  .p_left_arrow{
    margin-left:5px;
  }
  .p_right_arrow{
    margin-right:5px;
  }
  .sproject_wrapper{
    margin:20px 10px;
  }
  .sp_about{
    padding:20px 10px 40px;
  }
  .back_single_project{
    margin:20px 0px 0px 10px;
  }


}


@media only screen and (max-width: 550px) {
  .mp_info_right{
    display:none;
  }
  .mp_name{
    font-size: 22px;
  }
  .mp_company{
    font-size:16px;
  }
}

@media only screen and (max-width: 500px) {
  .mp_image_wrapper{
    height:120px;
    width:120px;
    top:-80px;
    left:20px;
    background-color: #fff;
    border:4px solid #fff;
  }
  .cover_body{
    padding:20px 10px;
  }
  .library_element{
    height: 40px;
  }
  .library_element img{
    height: 40px;
  }
  .about_wrapper{
    padding:30px 20px;
  }
  .more_wrapper{
    width: 160px;
  }
  .ex_main{
    flex-direction: column;
  }
  .project_img_wrap{
    margin-bottom:20px;
  }
  .ex_main_info{
    margin-left:0px;
  }
  .ex_body{
    margin:20px 0px 0px 0px;
  }
  .sp_about{
    padding:20px 10px 40px;
  }
  
}

@media only screen and (max-width: 400px) {
    .search{
        width:170px;
    }
    .searchResults{
      width:230px;
    }
    .main_cover_wrapper{
      height: 120px;
    }
    .cover_image{
      height:125px;
    }
    .skill_pass_text, .skill_endorsements{
      font-size: 14px;
    }
    .simage_preview{
      width:50px;
      height:50px;
    }
    .simage_preview img{
      width:50px;
      height:50px;
    }
}

@media only screen and (max-width: 330px) {
  .last_hr{
    margin-bottom:5px;
  }
  .signuout_wrapper{
    margin-bottom:0px;
  }
  .signout{
    margin-bottom:0px;
  }
  .profile_dropdown{
    padding:10px 10px 5px;
  }
}


